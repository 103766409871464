.full-blog-post {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: var(--light-background);
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    padding: 4rem;
    box-sizing: border-box;
    z-index: 10;

    &.open {
        right: 0;
    }

    & .content-wrapper {
        max-width: 1200px;
        font-size: clamp(1.3rem, 1vw, 1.8rem);
        line-height: 1.5;
        font-weight: 200;
        margin: 0 auto;


        & .featured-image {
            border-radius: 1rem;
        }
        
        & h1.blog-title {
            color: var(--zm-white);
            font-weight: 500;
            margin-bottom: 0;
        }

        & .date {
            color: var(--zm-white);
            font-weight: 300;
            font-size: clamp(.8rem, 1vw, 1rem);
            margin-bottom: 2rem;
        }

        & h2 {
            font-size: clamp(1.5rem, 1vw, 1.8rem);
            margin-bottom: 1.8rem;
        }
    }

    & .close-btn {
        position: absolute;
        top: 5%;
        right: 5%;
        font-size: 4em;
        background: none;
        font-weight: 600;
        border: none;
        color: var(--secondary-color);
        cursor: pointer;
    }
}

@media screen and (max-width:991px) {
    .content-wrapper {
        flex-direction: column;
        
    }
}

@media screen and (min-width:992px) {
    .content-wrapper {
        flex-direction: row;

        & .featured-image {
            max-width: 25%;
        }
    }
}


