#blog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

#blogsWrap {
  display: inline-flex;
  gap: 2rem;
}
