main.scroll-wrapper {
  min-height: 100vh;
  height: 100dvh;
  scroll-behavior: smooth;
}
  
.scroll-section {
  min-height: 100%;
  scroll-snap-align: center;
  background-color: var(--background-color);
  padding: 4rem;

  &:nth-child(even) {
    background-color: var(--background-color);
  }

}

@media screen and (min-width: 992px) {
  main.scroll-wrapper {
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  .scroll-section {
    padding: 8rem;
  }
}