#contactme {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8rem;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  & p {
    margin-bottom: 3rem;
  }
}

.contact-list {
  display: flex;
  gap: 2rem;

  & .contact-method {
    font-size: 2rem;
    transition: color 0.2s ease;
    &:hover {
      color: var(--highlight-color);
    }
  }
}


@media screen and (max-width: 991px) {
  #contactme {
    padding: 4rem;
  }
}
