
#projects {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

#projects .scroll-wrapper {
    height: 100vh;
    height: 100dvh;
    overflow-y: scroll;
    scroll-snap-type: x inline mandatory;
    scroll-behavior: smooth;
  }
  
  .projects-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
  }

  .project {
    width: 100%;
    max-width: 300px;
    height: 400px;
    position: relative;
    cursor: pointer;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    & .project-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(21, 27, 35, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-weight: 600;
      padding: 2rem 1rem;
      visibility: hidden;
      transition: height .2s ease-in-out;
      border-radius: 1rem;
      color: #fff;

      & h4.project-name {
        color: var(--secondary-color);
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: .2rem;
      }

      & .project-description {
        font-weight: 300;
        margin-bottom: 1.5rem;
      }

    }

    &:hover {
      z-index: 2;

      & .project-overlay {
        height: 100%;
        visibility: visible;
      }
    }

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: left;
      border-radius: 1rem;
    }
  }

  @media screen and (min-width: 992px) {

    .project {
      flex: 1 0 21%;
    }
  }