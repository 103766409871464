#sectionDots ul{
    height: 100vh;
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1.6em;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  
  .section-dot a {
    display: block;
    width: 16px;
    height: 16px;
    font-size: 1em;
    font-weight: 400;
    border: 2px solid var(--secondary-color);
    background-color: transparent;
    border-radius: 50px;
    transition: all .2s ease-in-out;
    position: relative;
  }
  
  .section-dot a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.45rem;
    width: 100%;
    height: 12px;

    transition: all .2s ease-in-out;
  }


  .section-dot.active a {
    background-color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    height: 32px;
  }

  .section-dot.active a:after {
    height: 30px;
  }


  [data-theme="dark"] .section-dot a:after,
  [data-theme="dark"] .section-dot.active a:after {
    -webkit-box-shadow: inset 0px 0px 0.4em 0px var(--secondary-color), 0px 0px 0.4em 0px var(--secondary-color);
    -moz-box-shadow: inset 0px 0px 0.4em 0px var(--secondary-color), 0px 0px 0.4em 0px var(--secondary-color);
    box-shadow: inset 0px 0px 0.4em 0px var(--secondary-color), 0px 0px 0.4em 0px var(--secondary-color);
  }


  @media screen and (max-width: 991px) {
  
    #sectionDots {
      display: none;
    }
  
  }