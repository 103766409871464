#intro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  h1 {
    font-weight: 600;
    text-transform: uppercase;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: .15rem;
    margin-bottom: 2rem;
  }

  a.view-portfolio {
    display: flex;
    align-items: center;
    width: max-content;
    gap: .8rem;
    transition: all .2s ease-in-out;

    .bolt {
      font-size: 2rem;
      color: var(--highlight-color);
      animation: border-flicker 1s linear infinite;
    }
  }

  a.view-portfolio:hover {
    font-size: 1.2rem;
    .bolt {
      color: var(--secondary-color);
      animation: none;
    }
  }
}
  
#intro::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../assets/images/cyber_space.png');
  background-position: center;
  background-size: cover;
  opacity: 1;
  z-index: -1;
  background-attachment: fixed;
}

@media screen and (min-width:992px) {
  #intro {
    background: linear-gradient(255deg, rgba(25,25,25,0) 50%, var(--background-color) 70%);
    padding: 8rem;
  }
}

@media screen and (max-width: 991px) {
  #intro {
    background: linear-gradient(180deg, rgba(25,25,25,0) 0%, var(--background-color) 95%);
    text-align: center;
    padding: 2rem;

    a.view-portfolio { 
      margin: 0 auto;
    }
  }
}