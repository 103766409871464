:root {

  /* Dark theme colors */
  --background-color: hsl(216, 28%, 7%);
  --light-background: rgb(21, 27, 35);
  --muted-color: hsl(0, 0%, 60%);
  --text-color: white;
  --highlight-color: hsl(167, 98%, 49%);
  --secondary-color: rgb(207, 68, 128);
  --tertiary-color: hsl(29, 95%, 64%);


  /* Light theme colors */
  --light-background-color: #f0f0f0;
  --light-text-color: #333;
  --light-highlight-color: hsl(167, 98%, 30%);
  --light-secondary-color: rgb(180, 50, 100);
  --light-tertiary-color: hsl(29, 95%, 40%);
  --light-muted-color: hsl(0, 0%, 40%);
  --light-light-background: #ffffff;
}

/* Light mode styles */
[data-theme="light"] {
  --background-color: var(--light-background-color);
  --light-background: var(--light-light-background);
  --muted-color: var(--light-muted-color);
  --text-color: var(--light-text-color);
  --highlight-color: var(--light-highlight-color);
  --secondary-color: var(--light-secondary-color);
  --tertiary-color: var(--light-tertiary-color);
}

@font-face {
  font-family: "Thunder";
  font-weight: 700;
  src: local("Thunder-Bold"),
       url("./assets/fonts/Thunder-Bold.woff") format("woff");
}

@font-face {
  font-family: "Thunder";
  font-weight: 800;
  src: local("Thunder-ExtraBold"),
       url("./assets/fonts/Thunder-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Thunder";
  font-weight: 900;
  src: local("Thunder-Black"),
       url("./assets/fonts/Thunder-Black.woff") format("woff");
}

* {
  margin:0;
  padding:0;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
}


h1 {
  font-family: 'Thunder', sans-serif;
  font-size: 4rem;
  margin-bottom: 0;
  font-weight: 700;
  line-height: normal;
}

h1 .highlight {
  color: var(--highlight-color);
  font-size: 10rem;
  font-weight: 900;
}

h2 {
  font-size: 2.4rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 2.5rem;
  font-family: 'Thunder', sans-serif;
  font-weight: 900;
}

p {
  font-size: 1.3rem;
  line-height: 2.3rem;
  margin-bottom: 2rem;
}

ol {
  list-style: decimal;
  padding-left: 2rem;
}

b {
  font-weight: 600;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* ====================
    ANIMATIONS
======================= */
  
@keyframes border-flicker {
  0% {opacity: 1;}
  1% {opacity: .1;}
  2% {opacity: 1;}
  2.5% { opacity: .1;}
  4% { opacity: 1;}
  20% { opacity: 1;}
  21% { opacity: .1;}
  22% { opacity: 1;}
  23% { opacity: .1;}
  24% { opacity: 1;}
  60% { opacity: 1;}
  61% { opacity: .1;}
  62% { opacity: 1;}
  62.5% { opacity: .1;}
  63% { opacity: 1;}
  63.5% { opacity: 1;}
  66% { opacity: .1;}
  67% { opacity: 1;}
  68% { opacity: .1;}
  69% { opacity: 1;}
  100% { opacity: 1;}
}




a.btn {
  display: block;
  width: max-content;
  color: var(--highlight-color);
  font-weight: 300;
  padding: .6rem 2rem;
  transition: all ease-in-out .2s;
}

a.btn:hover {
  background-color: var(--highlight-color);
  color: var(--background-color);
}



.glowing-btn {
  position: relative;
  display: block;
  width: max-content;
  padding: 1rem 2rem;
  text-decoration: none;
}

.glowing-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.45rem;
  width: 100%;
  height: 50px;
  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--highlight-color), 0px 0px 0.5em 0px var(--highlight-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--highlight-color), 0px 0px 0.5em 0px var(--highlight-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--highlight-color), 0px 0px 0.5em 0px var(--highlight-color);
}