.blog-list {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

@media screen and (min-width: 992px) {
    .blog-list {
        flex-direction: row;
    }
}