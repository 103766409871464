
#aboutme {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

#aboutText {
  flex: 1;
}

@media screen and (min-width: 992px) {
  
  #aboutme {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
  }

  #aboutText {
    flex: 2;
    padding-right: 3rem;
  }
}