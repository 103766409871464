header {
  display: flex;
  justify-content:flex-end;
  align-items: center;
  padding: 2rem;
  position: fixed;
  width: 100%;
  z-index: 2;
  transition: all ease-in-out .2s;
  background-color: transparent;
}