.nav-wrap {
  display: flex;
  align-items: center;
  gap: 4rem;
}

#mainNav ul,
#socialNav ul {
  display: inline-flex;
  gap: 4rem;
}

#mainNav a {
  transition: color .2s ease-in-out;
  font-size: .9rem;
  font-weight: 400;
}

#mainNav a,
#socialNav a {
  display: flex;
  padding: .9rem;
}

#mainNav li::after {
  content: "";
  display: block;
  width: 0%;
  transition: width .1s ease-in;
}

#mainNav li.active::after,
#socialNav li.active::after {
  border-bottom-width: 100%;
  width: 100%;
  height: 2px;
}

#socialNav ul li{
  font-size: 1.5em;

  a {
    transition: color .2s ease-in-out;

    &:hover {
      color: var(--highlight-color);
    }

    
  }
}

@media screen and (min-width: 992px) {
  #navBtn {
    display: none;
  }
  
  #mainNav,
  #socialNav {
    display: block;
  }
  
  [data-theme="dark"] {
    & #mainNav li::after {
      border-bottom: 2px solid hsl(167, 98%, 49%, 0.5);
      -webkit-box-shadow: inset 0px 0px 0.4em 0px var(--highlight-color), 0px 0px 0.4em 0px var(--highlight-color);
      -moz-box-shadow: inset 0px 0px 0.4em 0px var(--highlight-color), 0px 0px 0.4em 0px var(--highlight-color);
      box-shadow: inset 0px 0px 0.4em 0px var(--highlight-color), 0px 0px 0.4em 0px var(--highlight-color);
    }
  }

  [data-theme="light"] {

    & #mainNav li::after {
      border-bottom: 2px solid var(--text-color); 
    }

    
    & #mainNav li.intro.active::after {
      border-bottom: 2px solid #fff;
    }

    & #mainNav:has(li.nav-item.intro.active) a,
    #mainNav:has(li.nav-item.intro.active) ~ #socialNav a {
      color: #fff;
    }
  }

}

@media screen and (max-width: 991px) {

  #logo {
    display: block;
    z-index: 3;
  }

  /* ----- Burger Menu ----- */

  #navBtn {
    display: block;
    
    & label {
      display:flex;
      flex-direction:column;
      width:40px;
      cursor:pointer;
      
      & span{
        background: var(--highlight-color);
        border-radius:10px;
        height:6px;
        margin: 4px 0;
        transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
      
        &:nth-of-type(1){
          width:50%;  
        }
    
        &:nth-of-type(2){
          width:100%;
        }

        &:nth-of-type(3){
          width:70%;
        }
      }
    }

    & input[type="checkbox"] {
      display:none;
      
      &:checked ~ span:nth-of-type(1) {
        transform-origin:center;
        transform:rotatez(45deg) translate(8px, 0)
      }
    
      &:checked ~ span:nth-of-type(2) {
        transform-origin:top;
        transform:rotatez(-45deg)
      }

      &:checked ~ span:nth-of-type(3) {
        transform-origin:center;
        width:50%;
        transform: translate(20px,-8px) rotatez(45deg);
      }
    }
  }

  .nav-wrap {
    height: 0;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 0 2rem;
    background-color: var(--background-color);
    transition: all .3s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    & #mainNav,
    #socialNav,
    .theme-toggle-wrapper {
      position: absolute;
      left: -1000px;

      & a {
        font-size: clamp(2rem, 1vw, 2.5rem);
      }
    }

    & #mainNav {
      & ul {
        flex-direction: column;
        width: 100%;
      }
    }

    & #socialNav {
      bottom: 2.5rem;
    }

  }
  

  .nav-wrap.active {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
    padding: 8rem 2rem 4rem 2rem;
    
    & #mainNav, #socialNav, .theme-toggle-wrapper {
      left: unset;
    }
  }

}


/* New styles for when not in intro section */
[data-theme="light"] {
  & #mainNav a {
    color: var(--text-color); /* Default color */
  }
}

.not-in-intro [data-theme="light"] #mainNav a {
  color: var(--highlight-color); /* Color when not in intro */
}

