.theme-toggle-wrapper {
    display: flex;
    align-items: center;
  }
  
  .theme-toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .theme-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary-color);
    transition: .4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--text-color);
    transition: .4s;
  }
  
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .icon {
    font-size: 14px;
    width: 26px;
    height: 26px;
    transition: opacity 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  input:checked + .slider .icon:first-child,
  input:not(:checked) + .slider .icon:last-child {
    opacity: 0;
  }


  /* Dark Mode */

  input:not(:checked) + .slider {
      background-color: var(--muted-color);
    }
  
    
    input:not(:checked) + .slider:before {
      background-color: var(--light-background);
    }
  
    input:not(:checked) + .slider .icon.dark-mode {
      color: var(--highlight-color);
    }
  

      /* Light Mode */
  
    input:checked + .slider:before {
      background-color: var(--background-color);
    }
  
    input:checked + .slider {
      background-color: var(--light-muted-color);
    }
  
  
    input:checked + .slider .icon:last-child {
      color: var(--secondary-color);
    }
    
    @media screen and (max-width: 992px) {
      .theme-toggle-wrapper {
        top: 40px;
      }
    }
