[class*="blog-card-"] {
    max-width: 300px;
    width: auto;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: var(--light-background);
    padding: .8rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    &::after {
        content:"\f35d";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        font-size: clamp(1.5rem, 1vw, 2rem);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 0;
        background-color: var(--background-color);
        opacity: .0;
        transition: all ease-in-out .1s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        cursor: pointer;

        &::after {
            height: 100%;
            width: 100%;
            opacity: .9;
        }
    }

    & .feature-img {
        height: 100%;
        max-height: 200px;
        width: 100%;

        & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 1em 1em 0 0;
        }

    }

    & .blog-title {
        color: var(--text-color);
        font-weight: 600;
        font-size: clamp(1.5rem, 1vw, 2rem);
    }

    & .blog-date {
        display: block;
        color: var(--secondary-color);
        font-weight: 300;
        font-size: clamp( .7em, 1vw, .9rem);
        padding: 0;
        line-height: 1.4em;
        margin-bottom: 1.5em;
    }

    & .blog-details {
        width: 100%;
        padding: 1.6em 1.2em;
        display: flex;
        flex-direction: column;
        height: 100%;

        & .blog-link {
            margin-top: auto;
        }
    }

    & .blog-tease {
        display: block;
        color: var(--muted-color);
        font-weight: 300;
        font-size: clamp( .9em, 1vw, 1rem);
        padding: 0;
        line-height: 1.4em;
        margin-bottom: 1.5em;
    }
}